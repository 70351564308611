/*-------------------
  Instagram Styles
---------------------*/
.instagram-grid {
  text-align: center;
  overflow: hidden;
  height: 100%;
  a {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    display: block;
    max-height: 300px;
    height: 100%;
    img {
      border-radius: 5px;
      transition: var(--transition);
      overflow: hidden;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    video {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      object-fit: cover;
    }
    &:after {
      content: "";
      height: 100%;
      width: 100%;
      background-color: var(--color-heading);
      border-radius: 5px;
      opacity: 0.5;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      visibility: hidden;
      opacity: 0;
      transition: var(--transition);
    }
    .user-info {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%) scale(1.5);
      z-index: 1;
      color: var(--color-white);
      visibility: hidden;
      opacity: 0;
      transition: var(--transition);
      .icon {
        display: block;
        font-size: 26px;
      }
      .user-name {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  &:hover {
    a {
      img {
        transform: scale(1.1);
      }
      &:after {
        visibility: visible;
        opacity: 0.5;
      }
      .user-info {
        transform: translateY(-50%) scale(1);
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
