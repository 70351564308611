/*------------------------
    Feature Styles  
-------------------------*/
.rbt-feature {
    display: flex;
    transition: var(--transition-3);
    .icon {
        width: 50px;
        min-width: 50px;
        height: 50px;
        background: var(--color-secondary);
        display: flex;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        margin-right: 15px;

        i {
            color: var(--color-white);
            font-size: 24px;
        }

        &.bg-primary-opacity {
            i {
                color: var(--color-primary);
            }
        }

        &.bg-pink-opacity {
            i {
                color: var(--color-pink);
            }
        }

        &.bg-coral-opacity {
            i {
                color: var(--color-coral);
            }
        }

        &.bg-secondary-opacity {
            i {
                color: var(--color-secondary);
            }
        }
    }

    .feature-content {
        .feature-title {
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 0;
        }
        .feature-description {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 0;
            margin-top: 5px;
        }
    }
    & + .rbt-feature {
        margin-top: 30px;
        @media #{$sm-layout} {
            margin-top: 20px;
        }
    }
    &.feature-style-2 {
        padding: 20px;
        &:hover {
            box-shadow: var(--shadow-5);
            transform: scale(1.1);
            @media #{$sm-layout} {
                transform: scale(1);
            }
        }
        & + .feature-style-2 {
            margin-top: 10px;
        }
    }
}

.rbt-round-icon {
    width: 50px;
    min-width: 50px;
    height: 50px;
    background: var(--color-secondary);
    display: flex;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    i {
        color: var(--color-white);
        font-size: 24px;
    }

    &.bg-primary-opacity {
        i {
            color: var(--color-primary);
        }
    }

    &.bg-pink-opacity {
        i {
            color: var(--color-pink);
        }
    }

    &.bg-coral-opacity {
        i {
            color: var(--color-coral);
        }
    }

    &.bg-secondary-opacity {
        i {
            color: var(--color-secondary);
        }
    }

    &.bg-violet-opacity {
        i {
            color: var(--color-violet);
        }
    }

    &.bg-warning-opacity {
        i {
            color: var(--color-warning);
        }
    }
}


.rbt-single-course-features {
    .subtitle {
        margin-top: 40px;
        margin-bottom: 40px;
        @media #{$lg-layout} {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        @media #{$md-layout} {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        @media #{$sm-layout} {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}

