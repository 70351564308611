/*-----------------------
    Course Sidebar  
------------------------*/

/*-----------------------------
    Rbt Sidebar Common Style 
--------------------------------*/
.rbt-check-group {
    position: relative;
    label {
        display: flex;
        justify-content: space-between;
    }
}

.rbt-tag-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -5px;
    a {
        border: 0 none;
        padding: 0px 19px;
        text-transform: capitalize;
        background: var(--color-white);
        color: var(--color-body);
        box-shadow: var(--shadow-1);
        height: 36px;
        line-height: 36px;
        border-radius: 500px;
        font-size: 14px;
        display: block;
        transition: 0.4s;
        text-align: center;
        margin: 0 5px;
        margin-bottom: 10px;
        &:hover {
            background: var(--color-primary);
            color: var(--color-white);
        }
    }
}




/*---------------------------
    Rbt Show More Button  
------------------------------*/
.rbt-show-more-btn {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-primary);
    display: block;
    margin-top: 5px;
    position: relative;
    padding: 3px 0;
    cursor: pointer;
    &::before {
        background: linear-gradient(rgba(255,255,255,0) 0%, rgba(255, 255, 255, 1) 100%);
        position: absolute;
        content: "";
        left: 0;
        bottom: 100%;
        width: 100%;
        height: 80px;
    }
    &::after {
        content: "\e92e";
        position: absolute;
        font-family: 'feather';
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    &.active {
        &::before {
            background: none;
            display: none;
        }
        &::after {
            content: "\e931";
        }
    }
}


.has-show-more {
    .has-show-more-inner-content {
        max-height: 300px;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
    .rbt-course-details-list-wrapper {
        max-height: 230px;
    }

    .rbt-featured-review-list-wrapper {
        max-height: 550px;
    }

    &.active {
        .has-show-more-inner-content {
            max-height: inherit !important;
        }
    }
}

.filter-top-2 {
    .has-show-more {
        .has-show-more-inner-content {
            max-height: 120px;
        }
    }
}




/*---------------------------------
    Rbt Sidebar Widget Wrapper
-----------------------------------*/
.rbt-sidebar-widget-wrapper {
    box-shadow: var(--shadow-1);
    padding: 30px;
    border-radius: var(--radius);
    background: var(--color-white);
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    color: var(--color-heading);

    &.with-card-style {
        box-shadow: none;
        border-radius: inherit;
        background: inherit;
        padding: 0;
        &::before {
            display: none;
        }
        .rbt-single-widget {
            padding: 30px;
            height: 100%;
            border-radius: var(--radius);
            background: var(--color-white);
            box-shadow: var(--shadow-1);
        }
    }
}


/*------------------------
    Rbt Single Widget  
-------------------------*/

.rbt-single-widget {
    background: var(--color-white);
    padding-top: 30px;
    &:first-child {
        padding-top: 0;
    }


    .rbt-widget-title {
        font-size: 20px;
        padding-bottom: 15px;
        border-bottom: 2px solid var(--color-border);
        margin-bottom: 15px;
    }

    .rbt-widget-title-2 {
        font-size: 12px;
        padding-bottom: 15px;
        border-bottom: 2px solid var(--color-border);
        margin-bottom: 15px;
        color: var(--color-heading);
        text-transform: uppercase;
        letter-spacing: 0.5px;
        opacity: 0.8;
    }

    ul {
        &.rbt-sidebar-list-wrapper {
            @extend %liststyle;
            li {
                & + li {
                    margin-top: 10px;
                }
            }
            &.recent-post-list {
                li {
                    & + li {
                        margin-top: 15px;
                        padding-top: 10px;
                        border-top: 1px solid var(--color-border-2);
                    }
                }
            }
        }
    }



    input[type=checkbox] ~ label, 
    input[type=radio] ~ label {
        padding-left: 23px;
    }

    .rating {
        display: flex;
        align-items: center;
        margin-top: -3px;
        .off {
            color: var(--color-body);
            opacity: 0.4;
        }
    }

    .rbt-lable {
        background: var(--color-gray-light);
        padding: 5px 7px;
        font-size: 12px;
        display: flex;
        height: 22px;
        align-items: center;
        border-radius: var(--radius-small);
    }

    .recent-post-list {
        li {
            display: flex;
            align-items: center;
            .thumbnail {
                margin-right: 12px;
                a {
                    display: block;
                    img {
                        height: 60px;
                        width: 60px;
                        border-radius: var(--radius);
                        object-fit: cover;
                        min-width: 60px;
                        min-height: 60px;
                    }
                }
            }
            .content {
                .title {
                    margin-bottom: 12px;
                    font-size: 14px;
                }
                .rbt-meta {
                    li {
                        font-size: 12px;
                        i {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}












