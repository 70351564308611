/*-----------------------
    Custom Style  
-------------------------*/

.height-500 {
  min-height: 500px;
}

.msg-form-error {
  color: #ff0003;
}

.icon-big {
  i {
    font-size: 4rem;
  }
}

.float-whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.float-whatsapp img {
  padding: 2px;
  border-radius: 100px;
}

.card-category-course {
  margin-top: -20px; /* Valor mínimo */

  @media (min-width: 600px) {
    margin-top: calc(50px - 10vw);
  }

  @media (min-width: 1200px) {
    margin-top: -80px; /* Valor máximo */
  }

  .card-div {
    max-height: 420px;
    max-width: 300px;

    a {
      height: 100%;
      width: 100%;
    }
  }
}
