/*---------------------
    Social Share  
-----------------------*/
.social-share-transparent {
    display: flex;
    @extend %liststyle;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: -10px;
    @media #{$large-mobile} {
        margin: -5px;
    }
    li {
        margin: 0;
        position: relative;
        a {
            font-size: 14px;
            color: var(--color-body);
            display: inline-block;
            @extend %transition;
            display: flex;
            align-items: center;
            width: 30px;
            height: 30px;
            text-align: center;
            justify-content: center;
            border-radius: 100%;
            position: relative;
            z-index: 1;
            &::before {
                background: var(--color-gray-light);
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                @extend %transition;
                opacity: 0;
                transform: scale(0.8);
                border-radius: 100%;
                z-index: -1;
            }
            i {
                margin-right: 0px;
            }
            &:hover {
                color: var(--color-primary);
                &::before {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
    }
}
