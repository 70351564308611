// Advance tab Styles
.advance-tab-button-1 {
  .tab-button-list {
    list-style: none;
    padding: 0;
    margin: -10px 0;
    li {
      margin: 10px 0;
      @media #{$md-layout} {
        width: 100%;
      }
      @media #{$sm-layout} {
        width: 100%;
      }
    }
    .tab-button {
      cursor: pointer;
      padding: 47px 27px;
      border-radius: 10px;
      position: relative;
      z-index: 2;
      background-color: transparent;
      border: 0 none;
      margin: 0;
      @media #{$lg-layout} {
        padding: 18px 24px;
      }
      @media #{$md-layout} {
        padding: 25px 24px;
      }
      @media #{$sm-layout} {
        padding: 25px 24px;
      }
      &::before {
        position: absolute;
        content: "";
        background: var(--color-white);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: 0.3s;
        opacity: 0;
        transform: scale(0.9);
        z-index: -1;
        border-radius: 7px;
        box-shadow: var(--shadow-1);
      }
      &:hover {
        &::before {
          opacity: 1;
          transform: scale(1);
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 100%;
        border-left: 20px solid var(--color-white);
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        margin-top: -20px;
        opacity: 0;
        transition: 0.3s;
        transform: scale(0.9);
        @media #{$md-layout} {
          display: none;
        }
        @media #{$sm-layout} {
          display: none;
        }
      }
      .title {
        color: var(--color-heading);
        font-size: 20px;
        margin-bottom: 10px;
        transition: 0.3s;
        font-weight: 700;
        letter-spacing: -0.5px;
      }

      .description {
        margin-bottom: 0;
        transition: 0.3s;
        color: var(--color-body);
        letter-spacing: -0.5px;
      }
    }
    .tab-button {
      &.active {
        .title {
          letter-spacing: 0;
        }
        &::before {
          opacity: 1;
          transform: scale(1);
        }
        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}
.nav-tabs {
  border-bottom: 0;
}
.advance-tab-content-1 {
  .thumbnail {
    img {
      border-radius: 10px;
    }
  }
}

// Right Align

.advance-tab-button-1.right-align .tab-button-list .tab-button::after {
  transform: scale(0.9) rotate(-180deg);
  left: auto;
  right: 100%;
}
.advance-tab-button-1.right-align .tab-button-list .tab-button::after {
  transform: scale(1) rotate(-180deg);
}
.advance-tab-button-1.right-top .tab-button-list .tab-button::after {
  left: 50%;
  transform: scale(0.9) translateX(-50%) rotate(-92deg);
  bottom: 94%;
  top: auto;
}
.advance-tab-button-1.right-top .tab-button-list .tab-button::after {
  opacity: 0;
  transform: scale(1) translateX(-50%) rotate(-92deg);
}

.advance-tab-button-1.right-top .tab-button-list .tab-button.active::after {
  opacity: 1;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus {
  color: var(--color-body);
  background: transparent;
  border: 0 none;
  box-shadow: none;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}
.advance-tab-button-1 .tab-button-list .tab-button:focus {
  outline: none;
  border: 0 none;
  box-shadow: none;
}

/*-------------------------
    Advance Tab Style Two  
--------------------------*/
.tab-button-style-2 {
  border-bottom: 2px solid var(--color-border);
  justify-content: center;
  margin-bottom: 30px;
  padding: 0;
  list-style: none;
  @media #{$sm-layout} {
    margin-bottom: 20px;
  }
  li {
    margin-top: 0;
    margin-bottom: 0;
    a {
      position: relative;
      display: block;
      outline: none;
      padding: 16px 28px;
      border: 0 none;
      box-shadow: none;
      @media #{$md-layout} {
        padding: 16px 12px;
      }
      @media #{$sm-layout} {
        padding: 14px 10px;
      }
      &::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: -2px;
        left: 0;
        background-color: var(--color-primary);
        transform: scaleX(0);
        transition: transform 0.3s;
      }
      &.active {
        border: 0 none;
        outline: none;
        color: var(--color-primary);
        &::after {
          transform: scaleX(1);
        }
      }
      .title {
        font-size: 18px;
        margin-bottom: 0;
        font-weight: 500;
      }
    }
  }
}
.advance-tab-content-style-2 {
  text-align: center;
  padding: 0 15%;
  @media #{$lg-layout} {
    padding: 0 6%;
  }
  @media #{$md-layout} {
    padding: 0 7%;
  }
  @media #{$sm-layout} {
    padding: 0;
  }
  &.text-start {
    padding: 0;
    @media #{$lg-layout} {
      padding: 0;
    }
    @media #{$md-layout} {
      padding: 0;
    }
    @media #{$sm-layout} {
      padding: 0;
    }
  }
}

/*---------------------
    Tab Styles  
-----------------------*/
.rbt-default-tab {
  position: relative;
  // Tab Button
  .tab-button {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    border-bottom: none;
    .tabs__tab {
      margin: 0;
      .nav-link {
        color: var(--color-heading);
        padding: 24px 44px;
        margin-bottom: 0px;
        outline: none;
        border: 0 none;
        @media #{$lg-layout} {
          padding: 24px 24px;
        }
        @media #{$md-layout} {
          padding: 24px 24px;
        }
        @media #{$sm-layout} {
          padding: 24px 20px;
        }
        &.active {
          background: var(--color-extra2);
          border-color: transparent;
        }
      }
    }
    .rainbow-tab-button {
      button {
        padding: 0;
        margin: 0;
        background: transparent;
        border: 0 none;
        color: var(--color-heading);
      }
    }
  }
  // Tab Content
  .rainbow-tab-content {
    background: var(--color-extra2);
    padding: 40px;
    @media #{$sm-layout} {
      padding: 30px;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.style-two {
    .tab-button {
      margin-bottom: 30px;
      .tabs__tab {
        button {
          border-radius: var(--radius);
        }
      }
    }
    .rainbow-tab-content {
      border-radius: var(--radius);
    }
  }
}
